// 学情记录
import {get,put} from '../util/request.js';
import {showErrorTip} from '../util/index.js'

const getList = async (id) => {
  let url='/api/TeacherCourseStatusAPI';
  let data = {
    course_id:id
  }
  const res = await get({
    url,
    data
  });
  if(res.error){
    showErrorTip(res.data);
  }
  return res;
}

const modifyStatus  = async (status,id) => {
  let url='/api/TeacherCourseStatusAPI';
  let data = {
    id,
    status
  }
  const res = await put({
    url,
    data
  });
  if(res.error){
    showErrorTip(res.data);
  }
  return res;
}

const batchModifyStatus  = async (list=[]) => {
  let url='/api/TeacherBatchEditCourseStatusAPI';
  let data = list.map(item => {
    return{
      id:item.id,
      status:item.status
    }
  })
  const res = await put({
    url,
    data
  });
  if(res.error){
    showErrorTip(res.data);
  }
  return res;
}


export {
  getList,
  modifyStatus,
  batchModifyStatus
}