import {Tag,Select,Option,Input,Button,Pagination,Form,FormItem,DatePicker,Table,TableColumn,Radio } from 'element-ui';
import {getList,modifyStatus,batchModifyStatus} from '../api/student-record';
import { showSuccessTip } from '../util';
// import {showErrorTip} from '../util/index.js';
export default {
  name:'student-record',
  components:{
    elRadio:Radio,
    elTag:Tag,
    elSelect:Select,
    elOption:Option,
    elInput:Input,
    elButton:Button,
    elPagination:Pagination,
    elForm:Form,
    elFormItem:FormItem,
    elDatePicker:DatePicker,
    elTable:Table,
    elTableColumn:TableColumn
  },
  data(){

    this.courseid = this.$route.params.id;
    this.limit=20;
    return {
      disabled:false,
      statusEnum:[
        {
          label:'正常',
          value:'normal'
        },
        {
          label:'迟到',
          value:'tardiness'
        },
        {
          label:'旷课',
          value:'absenteeism'
        },
        {
          label:'请假',
          value:'leave'
        },
        {
          label:'早退',
          value:'early_departure'
        }
      ],
      list:[
        // {
        //   name:'入门课',
        //   time:'2020-09-08 08:00:00',
        //   status:''
        // },
        // {
        //   name:'进阶课',
        //   time:'2020-09-08 08:00:00',
        //   status:''
        // }
      ]
    }
  },
  async mounted(){
    // const res = await getBanner();
    // if(res.error !== null){
    //   showErrorTip(res.data);
    // }else{
    //   this.banners = res.data;
    // }
    this.getList();
    // this.getConfig();
  },
  methods:{
    async onSave(){
      console.log(this.list)
      const res = await batchModifyStatus(this.list);
      if(!res.error){
        showSuccessTip('保存成功');
        this.disabled = true;
      }
    },
    // async getConfig(){
    //   const res = await getConfig(); 
    //   const {data = {}} = res;
    //   const {index_project_tags = [],index_project_order_by=[]} = data;
    //   this.tags = index_project_tags;
    //   this.options = index_project_order_by;
    // },
    async getList(){
      const res = await getList(this.courseid); 
      this.list = res.data ||[];
    },
    async onRadioChange(status,id){
      modifyStatus(status,id);
    },
  
    pageTodetail(id){
      this.$router.push(`/course/${id}`);
    },
   
    async onSearch(){
      console.log('onsearch',this.searchParams)
      // const data = {
      //   limit:this.limit,
      //   offset:this.currentPage -1,
      //   keyword:this.input,
      //   tag:this.type,
      //   order_by:this.select
      // };
      // const res = await searchList(data);
      // this.list = res.data.results;
      // this.total = res.data.total;
    },
  }
}